export const StatusOptionsForAdmin = [
    {
        label: 'Draft',
        value: 'Draft',
        color: 'blue',
    },
    {
        label: 'Idea Approved',
        value: 'WIP',
        color: 'green',
    },
    {
        label: 'Story On Hold',
        value: 'Story On Hold',
        color: 'yellow',
    },
    {
        label: 'Published',
        value: 'Published',
        color: 'cyan',
    },
    {
        label: 'Pending Edit',
        value: 'Pending Edit',
        color: 'yellow',
    },
    {
        label: 'Rework',
        value: 'Rework',
        color: 'red',
    },
    {
        label: 'Rejected',
        value: 'Rejected',
        color: 'red',
    },
    {
        label: 'Edited',
        value: 'Edited',
        color: 'green',
    },
];

export const StatusOptions = [
    {
        label: 'Story On Hold',
        value: 'Story On Hold',
        color: 'yellow',
    },
    {
        label: 'WIP',
        value: 'WIP',
        color: 'purple',
    },
    {
        label: 'Pending Edit',
        value: 'Pending Edit',
        color: 'yellow',
    },
    {
        label: 'Published',
        value: 'Published',
        color: 'cyan',
    },
];


export const SumarryColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 150
    },
    {
        title: 'Draft',
        dataIndex: 'Draft',
        key: 'Draft',
    },
    {
        title: 'Idea Approved',
        dataIndex: 'Idea Approved',
        key: 'Idea Approved',
    },
    {
        title: 'Rejected',
        dataIndex: 'Rejected',
        key: 'Rejected',

    },
    {
        title: 'WIP',
        dataIndex: 'WIP',
        key: 'WIP',

    },
    {
        title: 'Story On Hold',
        dataIndex: 'Story On Hold',
        key: 'Story On Hold',

    },
    {
        title: 'Published',
        dataIndex: 'Published',
        key: 'Published',

    },
    {
        title: 'Total',
        dataIndex: 'Total',
        key: 'Total',

    },
];
export const SumarryColumnsEdited= [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 150
    },
    {
        title: 'Others',
        dataIndex: 'Others',
        key: 'Others',
    },
    {
        title: 'Published',
        dataIndex: 'Published',
        key: 'Published',

    },
    {
        title: 'Total',
        dataIndex: 'Total',
        key: 'Total',

    },
];
export const SumarryColumnsCategory = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 150
    },
    {
        title: 'News',
        dataIndex: 'News',
        key: 'News',
    },
    {
        title: 'Long-form',
        dataIndex: 'Long-form',
        key: 'Long-form',
    },
    {
        title: 'Daily Narrative',
        dataIndex: 'Daily Narrative',
        key: 'Daily Narrative',

    },
    {
        title: 'Newsletter',
        dataIndex: 'Newsletter',
        key: 'Newsletter',

    },
    {
        title: 'Brand content',
        dataIndex: 'Brand content',
        key: 'Brand content',

    },
    {
        title: 'Video',
        dataIndex: 'Video',
        key: 'Video',

    },
];

