import React from 'react'
// dependencies
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ProtectedRoute from './layout/ProtectedRoute'
import './App.css'
//pages
import LoginForm from './views/Login'
import ArticleHistory from './views/Home/ArticleHistory'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopilotKit } from "@copilotkit/react-core"; 
import "@copilotkit/react-ui/styles.css";

function App() {

  const router = createBrowserRouter([
    {
      path: '/home',
      element: (
        <ProtectedRoute>
          <ArticleHistory />
        </ProtectedRoute>
      ),
    },
    {
      path: '/login',
      element: <LoginForm />,
    },
    {
      path: '*',
      element: (
        <ProtectedRoute>
          <ArticleHistory />
        </ProtectedRoute>
      ),
    },
  ])

  return (
      <div className="App">
      <CopilotKit publicApiKey = {process.env.REACT_APP_COPILOT_CLOUD_KEY}> 
        <RouterProvider router={router} />
        <ToastContainer />
      </CopilotKit>
      </div>
  )
}

export default App
