import React, { useState } from 'react'
import AIMLogo from '../../assets/AIM-logo-black-1536x668.png'

//@antd
import { MailOutlined } from '@ant-design/icons'
import { Input, Button, message } from 'antd'

//router
import { useNavigate } from 'react-router-dom'
const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/

const LoginForm = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const [userDetails, setUserDetails] = useState({})
  const [showOTPField, setShowOTPField] = useState(false)
  const messageHandler = (title, status) => {
    messageApi.open({
      type: status,
      content: title,
      duration: 10,
    })
  }

  const [userEmail, setUserEmail] = useState('')

  const sendOTP = async (details) => {
    try {
      const response = await fetch(`${apiUrl}/v1/editorial/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(details),
      })
      const result = await response.json()
      if (result.data) {
        return result?.data
      }
    } catch (error) {
      console.log(error, 'here')
      throw error
    }
  }

  const createOTP = async (data) => {
    const request = {
      email: data?.email?.toLowerCase()?.trim(),
      password: undefined,
      fullName: data?.fullName?.trim(),
    }

    let response = await sendOTP(request)
    if (response?.email) {
      messageHandler(`OTP sent successfully to ${response?.email}`, 'success')
      setShowOTPField(true)
    } else {
      messageHandler('User Not Found, Please Register using AIM App.', 'error')
      setShowOTPField(false)
    }
  }

  const handleSubmitEmail = async () => {
    if (userEmail) {
      if (emailRegex.test(userEmail) === false) {
        messageHandler('Invalid Email', 'error')
        return false
      }

      const response = await fetch(`${apiUrl}/v1/users/userInfo/${userEmail}`)
      const userData = await response.json()

      if (userData?.data?.email && userData?.data?._id) {
        setUserDetails(userData?.data)
        createOTP(userData?.data)
      } else {
        messageHandler(
          'User Not Found, Please Register using AIM App.',
          'error',
        )
      }
    }
  }

  const signInUser = async (otp) => {
    const requestBody = {
      email: userDetails.email?.toLowerCase()?.trim(),
      password: otp,
      fullName: userDetails.fullName,
    }
    try {
      const response = await fetch(`${apiUrl}/v1/verifyOTP`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
      const result = await response.json()
      if (result?.data?.token) {
        localStorage.setItem('auth', JSON.stringify(result?.data))
        navigate('/home')
      }
      return result
    } catch (error) {
      setShowOTPField(false)
      messageHandler('Something went wrong!!!', 'error')
      return error
    }
  }

  const onChange = (otp) => {
    signInUser(otp)
  }
  const sharedProps = {
    onChange,
  }
  // User Not Found, Please Register using AIM App.
  return (
    <>
      {contextHolder}
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: '#FBE509',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '500px',
            height: '280px',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            padding: '20px',
            borderRadius: '15px',
          }}
        >
          <img
            src={AIMLogo}
            alt="surprize"
            className="img-fluid"
            loading="lazy"
            style={{ width: '100px', height: '50px' }}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'start',
              width: '100%',
              marginTop: '25px',
            }}
          >
            <p style={{ fontWeight: '600' }}>
              {showOTPField ? 'OTP' : 'Email'}
            </p>
            {showOTPField && (
              <p style={{ marginTop: '7px', fontSize: '12px' }}>
                Please enter the otp sent to your email {userDetails?.email}
              </p>
            )}
            {showOTPField ? (
              <Input.OTP
                style={{ marginTop: '15px' }}
                formatter={(str) => str.toUpperCase()}
                size={'large'}
                {...sharedProps}
              />
            ) : (
              <Input
                style={{ marginTop: '15px' }}
                size="large"
                placeholder="YOUR EMAIL"
                prefix={<MailOutlined />}
                onChange={(e) => {
                  setUserEmail(e?.target?.value)
                }}
                value={userEmail}
              />
            )}
            {!showOTPField ? (
              <Button
                onClick={() => {
                  handleSubmitEmail()
                }}
                type="primary"
                style={{
                  backgroundColor: '#FBE509',
                  color: 'black',
                  margin: '0 auto',
                  marginTop: '30px',
                  fontSize: '18px',
                  width: '170px',
                }}
              >
                Continue
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setShowOTPField(false)
                }}
                type="primary"
                style={{
                  backgroundColor: '#FBE509',
                  color: 'black',
                  margin: '0 auto',
                  marginTop: '30px',
                  fontSize: '14px',
                  width: '170px',
                }}
              >
                Enter a different email
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginForm
