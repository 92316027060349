
import React, { useState, useEffect } from 'react';
import s3 from '../../s3Config';
import { GoUpload } from "react-icons/go";
const ImageUpload = ({ record, userDetails }) => {
    let admin = userDetails?.roles?.includes('admin')
    let isCreative = userDetails?.roles?.includes('designer')
    const [images, setImages] = useState({
        banner: null,
        article: null,
        infoGraphics: null,
    });
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchImages = async () => {
            const response = await fetch(
                `${apiUrl}/v1/editorial/storyIdea/${record.status._id}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userDetails?.token}`,
                    },
                }
            );

            const result = await response.json();
            if (response.ok) {
                const newImages = {
                    banner: result.data.bannerUrl
                        ? { preview: result.data.bannerUrl }
                        : null,
                    article: result.data.articleUrl
                        ? { preview: result.data.articleUrl }
                        : null,
                    infoGraphics: result.data.infoGraphicsUrl
                        ? { preview: result.data.infoGraphicsUrl }
                        : null,
                };
                setImages(newImages);

            } else {
                console.error('Error fetching image URLs:', result.message);
            }
        };

        fetchImages();
    }, [apiUrl, record.status._id, userDetails?.token]);

    const handleImageChange = (event, type) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const newImage = {
                file: selectedFile,
                preview: URL.createObjectURL(selectedFile),
            };
            setImages((prevImages) => ({
                ...prevImages,
                [type]: newImage,
            }));
            uploadToS3(type, selectedFile);
        }
    };
    const cancelImage = (type) => {
        setImages((prevImages) => ({
            ...prevImages,
            [type]: null,
        }));

    };

    const uploadToS3 = async (type, file) => {
        if (!file) {
            alert(`Please select a file for ${type}.`);
            return;
        }

        const params = {
            Bucket: process.env.REACT_APP_S3_REGION_S3_BUCKET,
            Key: `editorial_articles/${Date.now()}_${file.name}`,
            Body: file,
            ContentType: file.type,
            ACL: "public-read",
        };

        s3.upload(params, async (err, data) => {
            if (err) {
                console.error('Error uploading file: ', err);
                alert('Error uploading file!');
                return;
            }

            const imageUrl = data.Location;

            const payload = { [`${type}Url`]: imageUrl };

            const response = await fetch(
                `${apiUrl}/v1/editorial/storyIdea/${record.status._id}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userDetails?.token}`,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const result = await response.json();
            if (response.ok) {
                alert(`${type} uploaded and saved successfully!`);

            } else {
                console.error('Error saving image to backend:', result.message);
                alert(`Error saving ${type} to backend: ${result.message}`);
            }
        });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >

                {!images.banner ? (
                    <>
                        {(admin || isCreative) && (
                            <label
                                style={{
                                    cursor: 'pointer',
                                    padding: '10px 20px',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    backgroundColor: '#f5f5f5',
                                    display: 'inline-block',
                                }}
                            >
                                <GoUpload /> Banner
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageChange(e, 'banner')}
                                    style={{ display: 'none' }}
                                />
                            </label>
                        )}
                    </>
                ) : (
                    <div style={{ position: 'relative' }}>
                        <img
                            src={images?.banner.preview}
                            alt="Banner Preview"
                            style={{
                                width: '100px',
                                height: 'auto',
                                objectFit: 'cover',
                                borderRadius: '5px',
                            }}
                        />
                        {(admin || isCreative) && (
                            <button
                                onClick={() => cancelImage('banner')}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    background: 'red',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '50%',
                                    width: '20px',
                                    height: '20px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                &times;
                            </button>
                        )}
                    </div>
                )}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                {!images.article ? (
                    <>
                        {(admin || isCreative) && (
                            <label
                                style={{
                                    cursor: 'pointer',
                                    padding: '10px 20px',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    backgroundColor: '#f5f5f5',
                                    display: 'inline-block',
                                }}
                            >
                                <GoUpload /> Article
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageChange(e, 'article')}
                                    style={{ display: 'none' }}
                                />
                            </label>
                        )}
                    </>
                ) : (
                    <div style={{ position: 'relative' }}>
                        <img
                            src={images?.article.preview}
                            alt="Article Preview"
                            style={{
                                width: '100px',
                                height: 'auto',
                                objectFit: 'cover',
                                borderRadius: '5px',
                            }}
                        />
                        {(admin || isCreative) && (
                            <button
                                onClick={() => cancelImage('article')}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    background: 'red',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '50%',
                                    width: '20px',
                                    height: '20px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                &times;
                            </button>
                        )}
                    </div>
                )}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                {!images.infoGraphics ? (
                    <>
                        {(admin || isCreative) && (
                            <label
                                style={{
                                    cursor: 'pointer',
                                    padding: '10px 20px',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    backgroundColor: '#f5f5f5',
                                    display: 'inline-block',
                                }}
                            >
                                <GoUpload /> infoGraphics
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageChange(e, 'infoGraphics')}
                                    style={{ display: 'none' }}
                                />
                            </label>
                        )}
                    </>
                ) : (
                    <div style={{ position: 'relative' }}>
                        <img
                            src={images?.infoGraphics.preview}
                            alt="Article Preview"
                            style={{
                                width: '100px',
                                height: 'auto',
                                objectFit: 'cover',
                                borderRadius: '5px',
                            }}
                        />
                        {(admin || isCreative) && (
                            <button
                                onClick={() => cancelImage('infoGraphics')}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    background: 'red',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '50%',
                                    width: '20px',
                                    height: '20px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                &times;
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageUpload;
